
                        $is-app_americas_ui-enabled: true;
                        @mixin americas-third-party-qualtrics {
    .c-qualtrics {
        a {
            color: inherit;
            font: inherit;
            text-decoration: inherit;
            
            &:hover,
            &:focus {
                color: inherit;
                text-decoration: inherit;
            }
        }
    }
}
                    