// Header
$header-background: color(light);
$header-sticked-background: color(light);
$header-sticked-search-active-border-top--large: 1px solid color(border);
$header-border-bottom: 1px solid color(border);
$header-sticked-border-bottom: $header-border-bottom;

// Logo
$header-logo-max-width: rem-calc(124);
$header-logo-max-width--large: rem-calc(260);
$header-sticked-logo-max-width: rem-calc(124);
$header-sticked-logo-max-width--large: rem-calc(180);
$header-logo-padding--large: rem-calc(30 0);

// Header Bottom
$header-bottom-background--large: color(light);
$header-border-bottom: none;

// Header top nav
$header-top-nav-right-padding--large: rem-calc(30 0 0);
$header-top-nav-item-font-size--large: rem-calc(10);
$header-top-nav-item-separator-color: color(border);
$header-top-nav-item-separator-width: rem-calc(1.5);
$header-top-nav-item-separator-height: rem-calc(16);
$header-top-nav-item-text-transform--large: capitalize;
$header-top-nav-left-padding--large: rem-calc(30 0 0);
$header-top-nav-link-font: #{rem-calc(10)} / 2 $font-primary;

// Header search
$header-search-button-background--large: color(secondary);
$header-search-button-background-hover--large: color(element-background);
$header-search-button-font-hover--large: null !default;
$header-search-button-border--large: 1px solid color(element-background);
$header-search-button-color--large: color(primary);
$header-search-button-icon-color--large: color(primary);
$header-search-button-text-transform: capitalize;

// Header sticke
$header-sticked-search-button-border--large: 0;
$header-sticked-top-nav-item-level-1-max-width--large: rem-calc(200);
$header-sticked-logo-padding--large: rem-calc(0 16 0 0);

@import "@lora/04-layout/header";