
                        $is-app_us_ui-enabled: true;
                        $personalized-label-button-margin: rem-calc(10 0 0) !default;
$personalized-label-button-checkbox-margin: rem-calc(0) !default;
$personalized-label-button-checkbox-margin--large: rem-calc(16 0) !default;
$personalized-label-button-checkbox-padding: rem-calc(16 8) !default;
$personalized-label-button-checkbox-margin: rem-calc(16 8) !default;
$personalized-label-button-checkbox-width: 100% !default;
$personalized-label-button-checkbox-background: color(light) !default;
$personalized-label-button-checkbox-left: 0 !default;
$personalized-label-button-checkbox-bottom: 100% !default;
$personalized-label-button-checkbox-position: absolute !default;
$personalized-label-button-button-height: rem-calc(40) !default;
$personalized-label-button-button-height--large: $personalized-label-button-button-height !default;

@mixin uslayer-components-personalized-label-button {
    .c-personalized-label-button {
        margin: $personalized-label-button-margin;
    }

    .c-personalized-label-button__checkbox {
        margin: $personalized-label-button-checkbox-margin--large;

        @include breakpoint(medium down) {
            position: $personalized-label-button-checkbox-position;
            bottom: $personalized-label-button-checkbox-bottom;
            left: $personalized-label-button-checkbox-left;
            background: $personalized-label-button-checkbox-background;
            width: $personalized-label-button-checkbox-width;
            padding: $personalized-label-button-checkbox-padding;
            margin: $personalized-label-button-checkbox-margin;
        }
    }

    .c-personalized-label-button__button {
        height: $personalized-label-button-button-height;

        @include breakpoint(large) {
            height: $personalized-label-button-button-height--large;
        }
    }
}
                    