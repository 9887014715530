$pick-up-in-store-button-box-text-margin: rem-calc(20 0) !default;
$pick-up-in-store-button-box-text-text-align: left !default;
$pick-up-in-store-button-box-edit-link-font-size: inherit !default;

@mixin americas-components-pick-up-in-store-button-box {
    .c-pick-up-in-store-button-box__text {
        margin: $pick-up-in-store-button-box-text-margin;
        text-align: $pick-up-in-store-button-box-text-text-align;

        .c-pick-up-in-store-button-box__edit-link {
            font-size: $pick-up-in-store-button-box-edit-link-font-size;
        }
    }
}