$footer-top-background: color(element-background);
$footer-background: color(light);
$footer-background--large: $footer-background;
$footer-middle-inner-border-bottom: rem-calc(1) solid color(border);
$footer-middle-inner-border-bottom--large: rem-calc(1) solid color(border);
$footer-middle-inner-padding--large: rem-calc(0 0 15);
$footer-bottom-border-top: none;
$footer-bottom-border-top--large: none;
$footer-newsletter-color: color(dark);
$footer-newsletter-color--large: $footer-newsletter-color;
$footer-newsletter-link-color: color(dark);
$footer-newsletter-link-color--large: $footer-newsletter-link-color;
$footer-newsletter-captcha-text-color: color(dark);
$footer-newsletter-captcha-text-color--large: $footer-newsletter-captcha-text-color;
$footer-social-margin: rem-calc(10 0 20);
$footer-social-margin--large: rem-calc(20 0 30);
$footer-row-variants-flex-direction: column-reverse;
$footer-row-item-last-padding-left: 0;
$footer-row-item-margin: rem-calc(5 0);
$footer-newsletter-margin--large: rem-calc(23 0 0);
$footer-error-color: color(alert);
$footer-newsletter-signup-title-font: #{rem-calc(14)} $font-secondary;
$footer-newsletter-signup-title-color: color(primary);
$footer-newsletter-signup-title-color--large: $footer-newsletter-signup-title-color;
$footer-newsletter-captcha-text-font: #{rem-calc(10)} $font-primary;
$footer-top-border-top: 1px solid color(border);

@import "@lora/04-layout/footer";