$tabs-tab-font: #{rem-calc(12)} / 1 $font-primary;
$tabs-tab-font--large: #{rem-calc(18)} / 1 $font-primary;
$tabs-tab-active-font: #{rem-calc(18)} / 1 $font-secondary;
$tabs-tab-active-font--large: #{rem-calc(18)} / 1 $font-secondary;
$tabs-tab-text-transform: uppercase;
$tabs-tertiary-tab-font: #{rem-calc(18)} / 1 $font-primary;
$tabs-tertiary-tab-font--large: #{rem-calc(18)} / 1 $font-primary;
$tabs-tab-active-color: color(secondary-active);
$tabs-tab-active-after-border-bottom: 2px solid color(highlight);
$tabs-tab-after-border-bottom: 1px solid color(element-background);
$tabs-secondary-control-items-border-bottom: 1px solid color(element-background);
$tabs-tab-active-after-border-bottom--large: 2px solid color(highlight);
$tabs-control-items-border-bottom: 1px solid color(secondary-active);
$tabs-tab-after-width: 100%;
$tabs-tab-margin: 0;
$tabs-tab-margin--large: 0;
$tabs-tab-padding: rem-calc(15 60 15 0);
$tabs-tab-padding--large: rem-calc(15 60 15 0);
$tabs-secondary-tab-padding: rem-calc(15 10);
$tabs-secondary-tab-padding--large: rem-calc(15 60 15 0);
$tabs-secondary-controls-background: transparent;
$tabs-secondary-content-background: transparent;
// Tabs tertiary
$tabs-tertiary-tab-font: #{rem-calc(14)} / 1 $font-primary;
$tabs-tertiary-tab-font--large: #{rem-calc(14)} / 1 $font-primary;
$tabs-tertiary-tab-color: color(text);
$tabs-tertiary-tab-active-color: $tabs-tertiary-tab-color;
$tabs-tertiary-tab-active-font: #{rem-calc(14)} / 1 $font-secondary;
$tabs-tertiary-tab-active-after-border-color: 2px solid color(highlight);
$tabs-tertiary-tab-text-transform: uppercase;
$tabs-tertiary-tab-text-transform--large: $tabs-tertiary-tab-text-transform;
$tabs-tertiary-tab-text-align: center;
$tabs-tertiary-controls-background-color: color(light);
$tabs-control-items-border-bottom: none;

@import "@lora/05-components/tabs";