$content-tile-title-text-transform: none;
$content-tile-title-text-transform--large: $content-tile-title-text-transform;
$content-tile-title-max-height: none;
$content-tile-title-max-height--large: none;
$content-tile-description-font: #{rem-calc(14)} / 1.25 $font-primary;
$content-tile-description-font--large: #{rem-calc(14)} / 1.25 $font-primary;
$content-tile-large-title-font--large: #{rem-calc(24)} / 1 $font-primary;
$content-tile-large-title-margin--large: rem-calc(0 0 15);
$content-tile-large-description-font--large: #{rem-calc(14)} / 1.5 $font-primary;
$content-tile-large-title-text-transform--large: uppercase;
$content-tile-large-description-font: #{rem-calc(14)} / 1.5 $font-primary;
$content-tile-title-font: #{rem-calc(14)} / 1 $font-secondary;
$content-tile-title-font--large: #{rem-calc(14)} / 1 $font-secondary;

@import "@lora/05-components/content-tile";