$product-tile-add-bag-button-min-width: 100%;
$product-tile-add-bag-button-min-width--large: rem-calc(150);
$product-tile-grid-figure-padding: rem-calc(0 10 50 0);
$product-tile-name-font-family: $font-secondary;
$product-tile-name-font-weight: normal;
$product-tile-name-font-size: rem-calc(14);
$product-tile-name-font-size--large: $product-tile-name-font-size;
$product-tile-name-text-transform: none;
$product-tile-info-separator: none;
$product-tile-variations-width--large: 100%;
$product-tile-price-font: #{rem-calc(12)} / 1.2 $font-primary;
$product-tile-price-font--large: $product-tile-price-font;
$product-tile-rating-align: center;
$product-tile-rating-empty-display: block;
$product-tile-info-item-align: center;
$product-tile-grid-name-text-align: left;
$product-tile-grid-description-text-align: $product-tile-grid-name-text-align;
$product-tile-grid-info-item-align-self: flex-start;
$product-tile-info-item-align--large: center;
$product-tile-badge-max-width: 60%;
$product-tile-variations-width--large: 60%;
$product-tile-default-text-align: center;
$product-tile-add-bag-text-transform: uppercase;
$product-tile-add-bag-text-transform--large: uppercase;
$product-tile-thumbnail-margin: rem-calc(0 0 34);
$product-tile-name-color-visited: color(primary-active);
// Product thumbnail
$product-tile-thumbnail-on-hover-badge-opacity: hidden;

@import "@lora/05-components/product/product-tile";