$pick-up-in-store-details-justify-content: space-between !default;
$pick-up-in-store-details-info-margin: rem-calc(0) !default;
$pick-up-in-store-details-info-margin--large: rem-calc(0 0 8) !default;
$pick-up-in-store-details-info-text-align: $global-right !default;

@mixin americas-components-pick-up-in-store-details {
    .c-pick-up-in-store-details {
        display: flex;
        justify-content: $pick-up-in-store-details-justify-content;
    }

    .c-pick-up-in-store-details__info {
        text-align: $pick-up-in-store-details-info-text-align;
        margin: $pick-up-in-store-details-info-margin;

        @include breakpoint(large) {
            margin: $pick-up-in-store-details-info-margin--large;
        }
    }
}