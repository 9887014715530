$product-tile-horizontal-name-link-text-transform: none;
$product-tile-horizontal-figure-margin: rem-calc(0 10 0 0);
$product-tile-horizontal-figure-margin--large: rem-calc(0 30 0 0);
$product-tile-horizontal-name-font-family: $font-secondary;
$product-tile-horizontal-name-font-size: rem-calc(14);
$product-tile-horizontal-name-font-weight: 700;
$product-tile-horizontal-variations-padding--large: rem-calc(0 0 0 20);
$product-tile-horizontal-actions-padding: rem-calc(10 0 0);
$product-tile-horizontal-secondary-figure-width: rem-calc(80);
$product-tile-horizontal-name-text-transform: none;
$product-tile-horizontal-info-width: 100%;
$product-tile-horizontal-info-justify-content: space-between;
$product-tile-horizontal-price-font: 700 #{rem-calc(14)} / 1.43 $font-secondary;
$product-tile-horizontal-variation-margin: rem-calc(0 0 10);
$product-tile-horizontal-info-item-padding--large: 0;
$product-tile-horizontal-secondary-figure-width--large: rem-calc(100);
$product-tile-horizontal-name-margin--large: rem-calc(0 0 8);
$product-tile-horizontal-name-margin: rem-calc(0 0 10);
$product-tile-horizontal-subtitle-margin: rem-calc(0 0 23);
$product-tile-horizontal-variation-margin--large: rem-calc(20 0 8);
$product-tile-horizontal-variation-font--large: #{rem-calc(12)} / 1.43 $font-primary;
$product-tile-horizontal-name-link-color: color(dark);

@import "@lora/05-components/product/product-tile-horizontal";