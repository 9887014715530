$header-suggestions-sections-separator: 1px solid color(disabled);
$header-suggestions-title-color: color(primary);
$header-suggestions-title-color--large: $header-suggestions-title-color;
$header-suggestions-title-text-transform: uppercase;
$header-suggestions-title-text-transform--large: $header-suggestions-title-text-transform;
$header-suggestions-view-all-font: #{rem-calc(14)} / 1.25 $font-primary;
$header-suggestions-view-all-color: color(primary);
$header-suggestions-products-name-color: color(primary);
$header-suggestions-products-name-font: #{rem-calc(14)} / 1.25 $font-secondary;
$header-suggestions-products-name-font--large: #{rem-calc(14)} / 1.25 $font-secondary;
$header-suggestions-products-price-color: color(primary);
$header-suggestions-row-flex-direction--large: row;
$header-suggestions-products-max-width--large: 50%;
$header-suggestions-products-padding--large: rem-calc(30 30 40 0);
$header-suggestions-content-only-padding--large: rem-calc(0 30 40);
$header-suggestions-content-only-margin--large: rem-calc(30 0 0);
$header-suggestions-view-all-right: rem-calc(30);
$header-suggestions-content-only-separator--large: 1px solid color(border);

@import "@lora/05-components/header/header-suggestions";