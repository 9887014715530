$section-title-font: #{rem-calc(24)} / 1.25 $font-primary;
$section-title-font--large: #{rem-calc(35)} / 1.25 $font-primary;
$section-title-text-align: center;
$section-title-margin--large: rem-calc(0 0 15);
$section-content-margin--large: rem-calc(20 0 0);
$section-item-margin: rem-calc(40 0 0);
$section-item-first-child-margin: rem-calc(40 0 0);
$section-secondary-title-text-align: center;
$section-headings-max-width--large: 100%;
$section-view-more-link-font: #{rem-calc(14)} / 1.5 $font-primary;
$section-view-more-link-font--large: #{rem-calc(14)} / 1.5 $font-primary;
$section-secondary-title-font: #{rem-calc(24)} / 1 $font-primary;
$section-description-font: #{rem-calc(14)} / 1.5 $font-primary;
$section-description-font--large: #{rem-calc(14)} / 1.5 $font-primary;
$section-description-color--large: color(text-secondary);
$section-description-color: color(text-secondary);

@import '@lora/05-components/section';