
                        $is-app_americas_ui-enabled: true;
                        // Americas variables
$liveagent-chat-position: fixed !default;
$liveagent-chat-position--large: relative !default;
$liveagent-chat-bottom: 45% !default;
$liveagent-chat-right: 0 !default;
$liveagent-chat-transform: rotate(-90deg) !default;
$liveagent-chat-transform--large: none !default;
$liveagent-chat-margin--large: 0 auto !default;
$liveagent-chat-height: rem-calc(30) !default;
$liveagent-chat-width: rem-calc(40) !default;
$liveagent-chat-width--large: auto !default;
$liveagent-chat-auto-invite-button-top: rem-calc(85) !default;
$liveagent-chat-auto-invite-button-right: rem-calc(100) !default;
$liveagent-chat-auto-invite-button-background-color: color(light) !default;
$liveagent-chat-auto-invite-button-text-color: color(primary) !default;
$liveagent-chat-auto-invite-button-font-size: rem-calc(16) !default;
$liveagent-chat-auto-invite-button-border: rem-calc(2) solid $liveagent-chat-auto-invite-button-text-color !default;

@mixin americas-components-liveagent-chat {
    .c-liveagent-chat {
        position: $liveagent-chat-position;
        bottom: $liveagent-chat-bottom;
        right: $liveagent-chat-right;
        transform: $liveagent-chat-transform;
        height: $liveagent-chat-height;
        width: $liveagent-chat-width;

        @include breakpoint(large) {
            position: $liveagent-chat-position--large;
            transform: $liveagent-chat-transform--large;
            margin: $liveagent-chat-margin--large;
            width: $liveagent-chat-width--large;
        }
    }
}
                    