$pick-up-in-store-item-availability-margin: rem-calc(8 0) !default;
$pick-up-in-store-item-availability-available-color: color(success) !default;
$pick-up-in-store-item-availability-notavailable-color: color(alert) !default;
$pick-up-in-store-item-availability-lowstock-color: color(warning) !default;
$pick-up-in-store-item-availability-margin--large: rem-calc(0) !default;
$pick-up-in-store-item-name-font-weight: 700 !default;

@mixin americas-components-pick-up-in-store-item {
    .c-pick-up-in-store-item__name {
        font-weight: $pick-up-in-store-item-name-font-weight;
    }

    .c-pick-up-in-store-item__availability {
        margin: $pick-up-in-store-item-availability-margin;

        &.m-available {
            color: $pick-up-in-store-item-availability-available-color;
        }

        &.m-notavailable {
            color: $pick-up-in-store-item-availability-notavailable-color;
        }

        &.m-lowstock {
            color: $pick-up-in-store-item-availability-lowstock-color;
        }

        @include breakpoint(large) {
            margin: $pick-up-in-store-item-availability-margin--large;
        }
    }
}