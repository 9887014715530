
                        $is-app_us_ui-enabled: true;
                        $gcd-display: flex !default;
$gcd-align-items: center !default;
$gcd-margin: rem-calc(0 0 15) !default;
$gcd-selected-width: 75% !default;
$gcd-designs-width: (100% - $gcd-selected-width) !default;
$gcd-designs-padding: rem-calc(0 0 0 8) !default;
$gcd-design-margin: rem-calc(8 0 0) !default;
$gcd-design-opacity: 0.5 !default;
$gcd-design-selected-opacity: 1 !default;
$gcd-design-hover-opacity: $gcd-design-selected-opacity !default;

@mixin uslayer-components-gcd {
    .c-gcd {
        display: $gcd-display;
        align-items: $gcd-align-items;
        margin: $gcd-margin;
    }

    .c-gcd__selected {
        width: $gcd-selected-width;
    }

    .c-gcd__designs {
        width: $gcd-designs-width;
        padding: $gcd-designs-padding;
    }

    .c-gcd__design {
        opacity: $gcd-design-opacity;

        &:not(:first-child) {
            margin: $gcd-design-margin;
        }

        &.m-selected {
            opacity: $gcd-design-selected-opacity;
        }

        &:hover {
            opacity: $gcd-design-hover-opacity;
        }
    }

    .c-gcd__design-label {
        cursor: pointer;
    }
}
                    