$og-modal-quickview-kh-ar-details-float: none !default;
$og-modal-quickview-kh-ar-details-right: auto !default;
$og-product-sticky-bar-sticked-offer-display: none !default;
$og-page-productdetail-widget-kh-ar-details-img-margin: rem-calc(0 15 -3 0) !default;
$og-page-productdetail-widget-iu-content-bottom-margin: rem-calc(0) !default;
$og-page-productdetail-widget-iu-content-bottom-margin--large: rem-calc(16 0) !default;
$og-page-productdetail-widget-iu-content-bottom-display: none !default;
$og-page-productdetail-widget-iu-content-bottom-display--large: block !default;
$og-selector-margin: rem-calc(20 0) !default;
$og-selector-margin--large: null !default;
$og-offer-picker-select-width: null !default;
$og-checkout-order-background-color: color(light) !default;
$og-checkout-order-border: 1px solid color(border) !default;
$og-checkout-order-padding: rem-calc(20 30) !default;
$og-checkout-order-display: block !default;

//stylelint-disable
@mixin uslayer-third-party-og {
    .c-ordergroove-selector {
        margin: $og-selector-margin;

        @include breakpoint(large) {
            margin: $og-selector-margin--large;
        }
    }
    .og-select::-ms-expand {
        display: none;
    }
    .c-modal {
        &.m-quickview {
            .og-offer {
                .kh-ar-details {
                    float: $og-modal-quickview-kh-ar-details-float !important;
                    right: $og-modal-quickview-kh-ar-details-right !important;
                }
            }
        }
    }
    .c-product-sticky-bar {
        &.m-sticked {
            .og-offer {
                display: $og-product-sticky-bar-sticked-offer-display;
            }
        }
    }
    .page-productdetail {
        .og-widget {
            .kh-ar-details {
                img {
                    margin: $og-page-productdetail-widget-kh-ar-details-img-margin !important;
                }
            }
            .og-iu-content-bottom {
                display: $og-page-productdetail-widget-iu-content-bottom-display !important;
                margin: $og-page-productdetail-widget-iu-content-bottom-margin !important;

                @include breakpoint(large) {
                    margin: $og-page-productdetail-widget-iu-content-bottom-margin--large !important;
                    display: $og-page-productdetail-widget-iu-content-bottom-display--large !important;
                }
            }
        }
    }
    .og-checkout-order {
        .og-type-ConfWidget {
            .conf-cont {
                margin-top: 0 !important;
                background-color: $og-checkout-order-background-color;
                border: $og-checkout-order-border;
                padding: $og-checkout-order-padding;
                display: $og-checkout-order-display;
            }

            a {
                text-decoration: underline !important;
            }
        }
    }
    og-formfield {
        textarea {
            margin-left:  0 !important;
        }
    }
    .og-offer {
        .og-picker {
            .og-select {
                width: $og-offer-picker-select-width;
            }
        }
    }
}