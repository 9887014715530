
                        $is-app_us_ui-enabled: true;
                        $personalized-label-lineitem-personalization-margin: rem-calc(16 0) !default;
$personalized-label-lineitem-personalization-stack-display: flex !default;
$personalized-label-lineitem-personalization-stack-item-margin: rem-calc(0 16 0 0) !default;
$personalized-label-lineitem-personalization-disclaimer-margin: rem-calc(16 0 0) !default;
$personalized-label-lineitem-personalization-disclaimer-color: color(alert) !default;

@mixin uslayer-components-lineitem-personalization {
    .c-lineitem-personalization {
        margin: $personalized-label-lineitem-personalization-margin;
    }

    .c-lineitem-personalization__stack {
        display: flex;
    }

    .c-lineitem-personalization__stack-item {
        margin: $personalized-label-lineitem-personalization-stack-item-margin;
    }

    .c-lineitem-personalization__disclaimer {
        margin: $personalized-label-lineitem-personalization-disclaimer-margin;
        color: $personalized-label-lineitem-personalization-disclaimer-color;
    }
}
                    