$footer-contacts-title-font: #{rem-calc(18)} / 1.45 $font-primary;
$footer-contacts-title-font--large: #{rem-calc(14)} / 1.45 $font-secondary;
$footer-contacts-title-margin: rem-calc(0 0 17);
$footer-contacts-title-margin--large: $footer-contacts-title-margin;
$footer-contacts-title-text-transform: none;
$footer-contacts-title-text-transform--large: $footer-contacts-title-text-transform;
$footer-contacts-link-font: #{rem-calc(14)} / 1.3 $font-primary;
$footer-contacts-link-background: color(dark);
$footer-contacts-link-color: color(light);
$footer-contacts-link-hover-color: color(light);
$footer-contacts-link-justify-content: center;
$footer-contacts-link-height: rem-calc(50);
$footer-contacts-link-border: 1px solid color(border);
$footer-contacts-link-text-transform: none;
$footer-contacts-link-flex-basis--medium-down: 100%;
$footer-contacts-link-first-child-margin: rem-calc(0 7 10 0);
$footer-contacts-link-last-child-margin: 0;
$footer-contacts-link-store-icon-height: rem-calc(35);
$footer-contacts-link-store-icon-width: rem-calc(20);
$footer-contacts-link-phone-icon-height: rem-calc(35);
$footer-contacts-link-phone-icon-width: rem-calc(26);
$footer-contacts-link-simple-hover-color: color(primary-active);
$footer-contacts-link-padding: rem-calc(6 12 7);
$footer-contacts-text-align--medium-down: center;
$footer-contacts-description-font: #{rem-calc(14)} / 1.45 $font-primary;
$footer-contacts-border-bottom: none;
$footer-contacts-padding: rem-calc(17 0 0);
$footer-contacts-description-color: color(dark);
$footer-contacts-title-color: color(dark);
$footer-contacts-title-color--large: $footer-contacts-title-color;

@import "@lora/05-components/footer/footer-contacts";