// Title
$modal-title-margin: rem-calc(0 0 24);
$modal-title-margin--large: rem-calc(0 0 24);
$modal-title-color: color(dark);
$modal-title-color--large: color(dark);
$modal-title-font: #{rem-calc(24)} / 1.33 $font-primary;
$modal-title-font--large: $modal-title-font;
$modal-title-text-transform: uppercase;
$modal-title-text-transform--large: $modal-title-text-transform;
$modal-title-text-align: center;
$modal-title-text-align--large: left;
$modal-wrapper-center-padding--large: rem-calc(40 62);
$modal-close-icon-size: rem-calc(18);
$modal-close-icon-size--large: $modal-close-icon-size;
$modal-body-font: #{rem-calc(14)} / 1.5 $font-primary;
$modal-header-font: $modal-title-font;
$modal-header-font--large: $modal-header-font;
$modal-header-text-transform: $modal-title-text-transform;
$modal-header-text-transform--large: $modal-title-text-transform--large;
$modal-header-text-align: $modal-title-text-align;
$modal-header-text-align--large: $modal-title-text-align--large;
// Subtitle
$modal-subtitle-font: #{rem-calc(14)} / 1.25 $font-secondary;
$modal-subtitle-font--large: $modal-subtitle-font;
$modal-image-width--large: rem-calc(480);
$modal-wrapper-padding: rem-calc(50 22 25);
$modal-wrapper-padding--medium: rem-calc(19 15 25);
$modal-wrapper-padding--large: rem-calc(19 70 70);

@import '@lora/05-components/modal';