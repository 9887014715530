$footer-nav-link-level1-color: color(text);
$footer-nav-link-level1-hover-color: color(primary-active);
$footer-nav-link-level1-hover-text-decoration: none;
$footer-nav-link-level1-text-transform--large: uppercase;
$footer-nav-list-level1-margin: rem-calc(18 0 18);
$footer-nav-list-level1-margin--large: rem-calc(25 0 18);
$footer-nav-list-level2-margin: rem-calc(0);
$footer-nav-link-level1-font: #{rem-calc(14)} / 1.25 $font-secondary;
$footer-nav-link-level1-font--large: #{rem-calc(14)} / 1.25 $font-secondary;
$footer-nav-link-level2-padding: rem-calc(3 0);
$footer-nav-link-level2-padding--large: rem-calc(0 0 6);
$footer-nav-link-level2-color: color(text);
$footer-nav-link-level2-hover-color: color(primary-active);
$footer-nav-link-level2-hover-text-decoration: none;
$footer-nav-link-level2-font--large: #{rem-calc(12)} / 1.3 $font-primary;

@import "@lora/05-components/footer/footer-nav";