
                        $is-app_membership-enabled: true;
                        $loyalty-price-color: color(highlight) !default;
$loyalty-price-flex: 1 1 100% !default;
$loyalty-price-gap: rem-calc(0 4) !default;
$loyalty-price-font-size: rem-calc(12) !default;
$loyalty-price-font-size--large: $loyalty-price-font-size !default;
$loyalty-price-font-weight: 700 !default;
$loyalty-price-font-weight--large: $loyalty-price-font-weight !default;
$loyalty-price-line-height: 1.5 !default;
$loyalty-price-line-height--large: $loyalty-price-line-height !default;
$loyalty-price-font: $loyalty-price-font-weight #{$loyalty-price-font-size} / $loyalty-price-line-height $font-primary !default;
$loyalty-price-font--large: $loyalty-price-font-weight--large #{$loyalty-price-font-size--large} / $loyalty-price-line-height--large $font-primary !default;
$loyalty-price-lines: 2 !default;
$loyalty-price-lines--large: 1 !default;
$loyalty-price-min-height: $loyalty-price-line-height*$loyalty-price-font-size*$loyalty-price-lines !default;
$loyalty-price-min-height--large: $loyalty-price-line-height--large*$loyalty-price-font-size--large*$loyalty-price-lines--large !default;
$loyalty-price-value-font-weight: bold !default;
$loyalty-price-label-flex: 1 1 100% !default;
$loyalty-price-label-flex--large: 0 1 auto !default;

@mixin lora-components-loyalty-price {
    .c-loyalty-price {
        font: $loyalty-price-font;
        color: $loyalty-price-color;
        display: flex;
        flex-wrap: wrap;
        flex: $loyalty-price-flex;
        gap: $loyalty-price-gap;

        @include breakpoint(large) {
            font: $loyalty-price-font--large;
        }
    }

    .c-loyalty-price__label {
        flex: $loyalty-price-label-flex;

        @include breakpoint(large) {
            flex: $loyalty-price-label-flex--large;
        }
    }

    .c-loyalty-price__value {
        font-weight: $loyalty-price-value-font-weight;
    }
}
                    