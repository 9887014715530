$pick-up-in-store-list-margin: rem-calc(6 0) !default;
$pick-up-in-store-list-item-margin-bottom: rem-calc(30) !default;
$pick-up-in-store-list-item-padding-bottom: rem-calc(30) !default;
$pick-up-in-store-list-item-border-bottom: 1px solid color(border) !default;
$pick-up-in-store-list-item-last-child-border-bottom: none !default;

@mixin americas-components-pick-up-in-store-list {
    .c-pick-up-in-store-list {
        margin: $pick-up-in-store-list-margin;

        &.m-empty {
            margin: 0;
        }
    }

    .c-pick-up-in-store-list__item {
        border-bottom: $pick-up-in-store-list-item-border-bottom;
        margin-bottom: $pick-up-in-store-list-item-margin-bottom;
        padding-bottom: $pick-up-in-store-list-item-padding-bottom;

        &:last-child {
            border-bottom: $pick-up-in-store-list-item-last-child-border-bottom;
        }
    }
}