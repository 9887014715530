$header-navigation-flyout-element-background--large: color(element-background);
$header-navigation-item-level-2-padding--large: rem-calc(35 0 30);
$header-navigation-item-title-level-2-font-size: rem-calc(14);
$header-navigation-item-title-level-2-color: color(dark);
$header-navigation-item-title-level-2-color--large: $header-navigation-item-title-level-2-color;
$header-navigation-link-level-1-color: color(dark);
$header-navigation-link-level-1-color--large: $header-navigation-link-level-1-color;
$header-navigation-item-level-3-padding--large: rem-calc(3 0 4);
$header-navigation-vertical-navigation-item-title-level-2-font-size--large: rem-calc(14);
$header-navigation-link-color: color(dark);
$header-navigation-link-font-size: rem-calc(14);
$header-navigation-link-text-transform: none;
$header-navigation-link-hover-font-weight: normal;
$header-navigation-link-level-2-color: color(dark);
$header-navigation-link-level-2-color--large: color(dark);
$header-navigation-link-level-2-font-weight: null;
$header-navigation-link-level-2-font-weight--large: null;
$header-navigation-link-level-2-font-size: rem-calc(14);
$header-navigation-link-level-2-font-size--large: rem-calc(13);
$header-navigation-link-level-2-text-transform--large: null;
$header-navigation-link-level-2-font-family: $font-primary;
$header-navigation-link-level-2-font-family--large: $font-secondary;
$header-navigation-link-level-2-hover-font-weight: null;
$header-navigation-item-title-level-1-expanded-background: color(element-background);
$header-navigation-item-title-level-1-expanded-color: color(text-secondary);
$header-navigation-item-title-level-1-expanded-font: #{rem-calc(14)} / 1 $font-secondary;
$header-navigation-link-level-1-font-size--large: #{rem-calc(12)};
$header-navigation-link-level-1-padding--large: rem-calc(14 15);
$header-navigation-item-title-level-2-expanded-background: color(light);
$header-navigation-item-title-level-2-background: color(light);
$header-navigation-item-title-level-3-background: color(light);
$header-navigation-item-title-level-2-expanded-color: color(dark);
$header-navigation-item-title-level-2-font-weight: normal;
$header-navigation-item-title-level-2-font-weight--large: $header-navigation-item-title-level-2-font-weight;
$header-navigation-item-level-3-all-link-color--large: color(dark);
$header-navigation-item-level-3-all-link-font: #{rem-calc(14)} / 1.6 $font-primary;
$header-navigation-item-level-3-all-link-font--large: $header-navigation-item-level-3-all-link-font;
$header-navigation-item-border-bottom: 1px solid color(element-background);
$header-navigation-item-first-border-top: $header-navigation-item-border-bottom;
$header-navigation-item-level-3-all-link-text-decoration: underline;
$header-navigation-item-level-3-all-link-text-decoration--large: $header-navigation-item-level-3-all-link-text-decoration;

// Content tile
$header-navigation-content-margin--large: rem-calc(0 25 0 0);
$header-navigation-content-title-text-transform--large: capitalize;

@import "@lora/05-components/header/header-navigation";